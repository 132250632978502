import { ServiceRequest } from "src/utils/service-request"
import { CETIP_EMISSAO_PATH, DATALAKE_EMISSAO_CARACTERISTICAS_PATH, DATALAKE_EMISSAO_LTV_PATH, DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH, DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH, DATALAKE_RATING_DEVEDOR_PATH, DATALAKE_RATING_EMISSAO_PATH, DATALAKE_SPREADS_NEGOCIADOS, ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH, ULTIMO_EVENTO_CREDITO_EMISSAO_PATH, ULTIMO_RATING_DEVEDOR_PATH, ULTIMO_RATING_EMISSAO_PATH, ULTIMO_SPREADS_NEGOCIADOS_PATH } from "src/utils/api-routes"
import { removeValoresVaziosPayloadTrocaPorNull } from "src/utils/object"
import { formatSlashDate } from "src/utils/format-date"
import { getTextValue } from "src/utils/strings"
import { getFormattedNumberValue, removerPontosVirgulaValor } from "src/utils/format-decimal"

class EmissaoService {
    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async listCetipIsinEmissao(cetipIsin) {
        return this.serviceRequest.get(`${CETIP_EMISSAO_PATH}?cetip_isin=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoRatingEmissao(cetipIsin) {
        return this.serviceRequest.get(`${ULTIMO_RATING_EMISSAO_PATH}?cetip=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoRatingDevedor(cpfCnpj) {
        return this.serviceRequest.get(`${ULTIMO_RATING_DEVEDOR_PATH}?cpf_cnpj=${cpfCnpj}`)
    }

    async getUltimoEventoCreditoEmissao(cetipIsin) {
        return this.serviceRequest.get(`${ULTIMO_EVENTO_CREDITO_EMISSAO_PATH}?cetip=${cetipIsin.toUpperCase()}`)
    }

    async getUltimoEventoCreditoDevedor(cpfCnpj) {
        return this.serviceRequest.get(`${ULTIMO_EVENTO_CREDITO_DEVEDOR_PATH}?cpf_cnpj=${cpfCnpj}`)
    }

    async getUltimoSpreadNegociados(cetip) {
        return this.serviceRequest.get(`${ULTIMO_SPREADS_NEGOCIADOS_PATH}?cetip=${cetip.toUpperCase()}`)
    }

    async ratingEmissao(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_RATING_EMISSAO_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_RATING_EMISSAO_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_RATING_EMISSAO_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_RATING_EMISSAO_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async ratingDevedor(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_RATING_DEVEDOR_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_RATING_DEVEDOR_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_RATING_DEVEDOR_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_RATING_DEVEDOR_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }
    async eventoCreditoEmissao(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_EVENTOS_CREDITO_EMISSAO_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async eventoCreditoDevedor(method, value, body = null) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}`, value)
            case "patch":
                return this.serviceRequest.patch(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}/${value}`, body)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_EVENTOS_CREDITO_DEVEDOR_PATH}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async ultimoNegocios(method, value) {
        value = removeValoresVaziosPayloadTrocaPorNull(method, value)
        switch (method) {
            case "get":
                return this.serviceRequest.get(`${DATALAKE_SPREADS_NEGOCIADOS}${value}`)
            case "post":
                return this.serviceRequest.post(`${DATALAKE_SPREADS_NEGOCIADOS}`, value)
            case "delete":
                return this.serviceRequest.delete(`${DATALAKE_SPREADS_NEGOCIADOS}/${value}`)
            default:
                throw new Error("Invalid method")
        }
    }

    async registerCaracteristicasEmissao(value) {
        value = value || {}

        const body = {
            CETIP: value.CETIP || "",
            VersaoDocumento: value.VersaoDocumento || "",
            DataDocumentacao: value.DataDocumentacao
                ? formatSlashDate(value.DataDocumentacao)
                : "",
            TipoAmortizacao: value.TipoAmortizacao || "",
            CotaSubordinacao: value.CotaSubordinacao || "",
            ValorSerie: value.ValorSerie || "",
            ValorTotalEmissao: value.ValorTotalEmissao || "",
            AgenteFiduciario: value.AgenteFiduciario || "",
            CoordenadorLider: value.CoordenadorLider || "",
            IFContaRecebedora: value.IFContaRecebedora || "",
            Securitizador: value.Securitizador || "",
            Custodiante: value.Custodiante || "",
            PrePagamento: value.PrePagamento || "",
            Cedente: value.Cedente || "",
            Auditoria: value.Auditoria || "",
            AgenciaRating: value.AgenciaRating || "",
            Devedor: value.Devedor || "",
            CNPJ_CPF_Devedor: value.CNPJ_CPF_Devedor || "",
            ResumoDevedor: value.ResumoDevedor || "",
            TipoLastro: value.TipoLastro || "",
            Coobrigacao: value.Coobrigacao || "",
            ContaVinculada: value.ContaVinculada || "",
            Covenants: value.Covenants || "",
            RepactuacaoCredito: value.RepactuacaoCredito || "",
            AlienacaoFiduciaria: getTextValue(value.AlienacaoFiduciaria) || "Não tem",
            CessaoFiduciaria: getTextValue(value.CessaoFiduciaria) || "Não tem",
            FiancaBancaria: getTextValue(value.FiancaBancaria) || "Não tem",
            Hipoteca: getTextValue(value.Hipoteca) || "Não tem",
            Penhor: getTextValue(value.Penhor) || "Não tem",
            Avalista: getTextValue(value.Avalista) || "Não tem",
            Fianca: getTextValue(value.Fianca) || "Não tem",
            FundoObra: getTextValue(value.FundoObra) || "Não tem",
            FundoReserva: getTextValue(value.FundoReserva) || "Não tem",
            FundoLiquidez: getTextValue(value.FundoLiquidez) || "Não tem",
            Seguro: getTextValue(value.Seguro) || "Não tem",
            Sobregarantia: getTextValue(value.Sobregarantia) || "Não tem",
        }
        console.log(DATALAKE_EMISSAO_CARACTERISTICAS_PATH, body)
        // return this.serviceRequest.post(DATALAKE_EMISSAO_CARACTERISTICAS_PATH, body)
    }

    async registerLTVEmissao(value) {
        value = value || {}

        const body = {
            CETIP: value.CETIP || "",
            DataReferencia: value.DataReferencia
                ? formatSlashDate(value.DataReferencia)
                : "",
            IndiceSubordinacao: removerPontosVirgulaValor(value.IndiceSubordinacao) || 0,
            LTV: removerPontosVirgulaValor(value.LTV) || 0,
            AlienacaoFiduciaria: getFormattedNumberValue(value.AlienacaoFiduciaria) || 0,
            CessaoFiduciaria: getFormattedNumberValue(value.AlienacaoFiduciaria) || 0,
            FiancaBancaria: getFormattedNumberValue(value.AlienacaoFiduciaria) || 0,
            Hipoteca: getFormattedNumberValue(value.Hipoteca) || 0,
            Penhor: getFormattedNumberValue(value.Penhor) || 0,
            Avalista: getFormattedNumberValue(value.Avalista) || 0,
            Fianca: getFormattedNumberValue(value.Fianca) || 0,
            FundoObra: getFormattedNumberValue(value.FundoObra) || 0,
            FundoReserva: getFormattedNumberValue(value.FundoReserva) || 0,
            FundoLiquidez: getFormattedNumberValue(value.FundoLiquidez) || 0,
            Seguro: getFormattedNumberValue(value.Seguro) || 0,
            Sobregarantia: getFormattedNumberValue(value.Sobregarantia) || 0,
        }
        console.log(DATALAKE_EMISSAO_LTV_PATH, body)
        // return this.serviceRequest.post(DATALAKE_EMISSAO_LTV_PATH, body)
    }

}

export default EmissaoService