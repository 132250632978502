import { Button, DialogActions, Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import EmissaoService from "src/services/emissao-service"
import PropTypes from 'prop-types'
import DoubleInput from "src/components/double-inputs"
import { formatInputDate } from "src/utils/format-date"
import TextFieldFormatCurrency from "src/components/text-field-format-currency"
import { formatDecimalCaseWithComma, getFormattedNumberValue, removerPontosVirgulaValor } from "src/utils/format-decimal"

const inputs = [
    { label: "CETIP", value: "CETIP", required: true },
    { label: "Versão Documento", value: "VersaoDocumento", required: true },
    { label: "Data Documentação", value: "DataDocumentacao", required: true, type: "date" },
    { label: "Cota Subordinação", value: "CotaSubordinacao", required: false },
    { label: "Série", value: "Serie", required: false },
    { label: "Emissão", value: "Emissao", required: false },
    { label: "Tipo Amortização", value: "TipoAmortizacao", required: false },
    { label: "Valor Série", value: "ValorSerie", required: false, type: "number" },
    { label: "Valor Total Emissão", value: "ValorTotalEmissao", required: false, type: "number" },
    { label: "Pre Pagamento", value: "PrePagamento", required: false },
    { label: "Repactuação Crédito", value: "RepactuacaoCredito", required: true },
    { label: "Conta Vinculada", value: "ContaVinculada", required: false },
]

const inputsTextArea = [
    { label: "Resumo Devedor", value: "ResumoDevedor", required: false },
    { label: "Tipo de Lastro", value: "TipoLastro", required: false },
    { label: "Coobrigação", value: "Coobrigacao", required: false },
    { label: "Covenants", value: "Covenants", required: false },
]

const inputsDoubleLine = [
    { label: "Devedor", value: "Devedor", required: false },
    { label: "CNPJ/CPF Devedor", value: "CNPJ_CPF_Devedor", required: false },
    { label: "Securitizador", value: "Securitizador", required: false },
    { label: "Agência de Rating", value: "AgenciaRating", required: false },
    { label: "Agente Fiduciário", value: "AgenteFiduciario", required: false },
    { label: "Auditoria", value: "Auditoria", required: false },
    { label: "Cedente", value: "Cedente", required: false },
    { label: "Coordenador Líder", value: "CoordenadorLider", required: false },
    { label: "Custodiante", value: "Custodiante", required: false },
    { label: "IF Conta Recebedora", value: "IFContaRecebedora", required: false },
]

const ltvInputs = [
    { label: "Data Referência", value: "DataReferencia", required: true, type: "date" },
    { label: "Índice de Subordinação", value: "IndiceSubordinacao", required: false, type: "number" },
    { label: "LTV", value: "LTV", required: true, type: "number" },
]

const doubleInputs = [
    { label: "Alienação Fiduciária", value: "AlienacaoFiduciaria", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Cessão Fiduciária", value: "CessaoFiduciaria", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Hipoteca", value: "Hipoteca", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Penhor", value: "Penhor", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Avalista", value: "Avalista", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fiança", value: "Fianca", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fundo de Obra", value: "FundoObra", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fundo Reserva", value: "FundoReserva", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fundo de Liquidez", value: "FundoLiquidez", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Seguro", value: "Seguro", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Sobregarantia", value: "Sobregarantia", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
    { label: "Fiança Bancária", value: "FiancaBancaria", required: false, defaultValue: { text: "Não tem", number: 0 } }, //
]

const RegisterCetip = ({ loading, afterRegister }) => {
    const [valueBody, setValueBody] = useState({})
    const emissaoService = new EmissaoService()

    const onChangeInput = (event) => {
        const { target } = event

        let inputValue = target.value
        const inputName = target.name
        const idInput = target.id

        if (idInput === "date") {
            inputValue = formatInputDate(inputValue)
        }

        if (inputName === "CETIP") {
            // verifyExistCETIP()
        }

        calculaLTVSetBody(inputName, inputValue)
    }

    const onChangeDoubleInput = (valueInput) => {
        const { key, values } = valueInput
        calculaLTVSetBody(key, values)
    }

    const calculaLTVSetBody = (key, value) => {
        let body = { ...valueBody, ...{ [key]: value, } }
        let ltvValue = ""

        if (key !== "LTV") {
            ltvValue = calculaLTV(body)
            body = { ...valueBody, ...{ [key]: value, "LTV": ltvValue } }
        }

        setValueBody(body)
    }

    const calculaLTV = (body) => {
        const preparaCampos = {
            ValorSerie: removerPontosVirgulaValor(body.ValorSerie) || 0,
            ValorTotalEmissao: removerPontosVirgulaValor(body.ValorTotalEmissao) || 0,
            IndiceSubordinacao: removerPontosVirgulaValor(body.IndiceSubordinacao) || 0,
            AlienacaoFiduciaria: getFormattedNumberValue(body.AlienacaoFiduciaria) || 0,
            CessaoFiduciaria: getFormattedNumberValue(body.CessaoFiduciaria) || 0,
            FiancaBancaria: getFormattedNumberValue(body.FiancaBancaria) || 0,
            Hipoteca: getFormattedNumberValue(body.Hipoteca) || 0,
            Penhor: getFormattedNumberValue(body.Penhor) || 0,
            Avalista: getFormattedNumberValue(body.Avalista) || 0,
            Fianca: getFormattedNumberValue(body.Fianca) || 0,
            FundoObra: getFormattedNumberValue(body.FundoObra) || 0,
            FundoReserva: getFormattedNumberValue(body.FundoReserva) || 0,
            FundoLiquidez: getFormattedNumberValue(body.FundoLiquidez) || 0,
            Seguro: getFormattedNumberValue(body.Seguro) || 0,
            Sobregarantia: getFormattedNumberValue(body.Sobregarantia) || 0,
        }

        const {
            ValorSerie = 0,
            ValorTotalEmissao = 0,
            IndiceSubordinacao = 0,
            AlienacaoFiduciaria = 0,
            CessaoFiduciaria = 0,
            FiancaBancaria = 0,
            Hipoteca = 0,
            Penhor = 0,
            Avalista = 0,
            Fianca = 0,
            FundoObra = 0,
            FundoReserva = 0,
            FundoLiquidez = 0,
            Seguro = 0,
            Sobregarantia = 0,
        } = preparaCampos

        const sum =
            AlienacaoFiduciaria +
            CessaoFiduciaria +
            FiancaBancaria +
            Hipoteca +
            Penhor +
            Avalista +
            Fianca +
            FundoObra +
            FundoReserva +
            FundoLiquidez +
            Seguro +
            Sobregarantia

        // Prevenir divisão por zero
        const denominator = (ValorTotalEmissao * IndiceSubordinacao) + sum

        if (denominator === 0) {
            console.log("Divisão por zero detectada no cálculo do LTV.")
        }

        const somaFinal = (ValorSerie / denominator) * 100
        return formatDecimalCaseWithComma(somaFinal, 4)
    }

    const register = () => {
        const promises = [
            emissaoService.registerCaracteristicasEmissao(valueBody),
            emissaoService.registerLTVEmissao(valueBody)
        ]

        loading(true)
        Promise.all(promises)
            .then(() => {
                afterRegister("Novo Cetip Cadastrado", "success")
            })
            .catch((err) => {
                const errBody = JSON.stringify(err)
                const errObject = JSON.parse(errBody)
                const { response } = errObject
                const { text } = response
                afterRegister(text, "error")
            })
            .finally(() => {
                loading(false)
            })
    }

    return (
        <>
            {/* inputs */}
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputs.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            {input.type !== "number" ?
                                <TextField
                                    fullWidth
                                    label={input.label}
                                    value={valueBody[input.value]}
                                    name={input.value}
                                    id={input.type ? input.type : "text"}
                                    required={input.required}
                                    inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                    onChange={onChangeInput}
                                />
                                :
                                <TextFieldFormatCurrency
                                    label={input.label}
                                    variant="outlined"
                                    key={input.label}
                                    value={valueBody[input.value]}
                                    onChange={(value) => onChangeInput({
                                        target: {
                                            name: input.value,
                                            id: "number",
                                            value: value,
                                        },
                                    })}
                                />
                            }
                        </Grid>
                    ))
                }
            </Grid>

            {/* double line */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputsDoubleLine.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            <TextField
                                multiline
                                fullWidth
                                rows={2}
                                label={input.label}
                                value={valueBody[input.value]}
                                name={input.value}
                                id={input.type ? input.type : "text"}
                                required={input.required}
                                inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                onChange={onChangeInput} />
                        </Grid>
                    ))
                }
            </Grid>

            {/* text area */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    inputsTextArea.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                value={valueBody[input.value]}
                                label={input.label}
                                name={input.value}
                                required={input.required}
                                inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                onChange={onChangeInput} />
                        </Grid>

                    ))
                }
            </Grid>

            {/* ltvInputs */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    ltvInputs.map((input) => (
                        <Grid item xs={12} md={6} xl={3} key={input.label}>
                            {input.type !== "number" ?
                                <TextField
                                    fullWidth
                                    label={input.label}
                                    value={valueBody[input.value]}
                                    name={input.value}
                                    id={input.type ? input.type : "text"}
                                    required={input.required}
                                    inputProps={input.type === "date" ? { maxLength: 10 } : {}}
                                    onChange={onChangeInput} />
                                :
                                <TextFieldFormatCurrency
                                    focused
                                    label={input.label}
                                    variant="outlined"
                                    key={input.label}
                                    value={valueBody[input.value]}
                                    onChange={(value) => onChangeInput({
                                        target: {
                                            name: input.value,
                                            id: "number",
                                            value: value,
                                        },
                                    })}
                                />
                            }
                        </Grid>
                    ))
                }
            </Grid>

            {/* doubleInputs */}
            <br />
            <Grid direction={"row"} container alignItems="center" justifyContent="flex-start" spacing={3}>
                {
                    doubleInputs.map((input) => (
                        <Grid item xs={12} md={6} xl={6} key={input.label}>
                            <DoubleInput input={input} multiline={true} multilineRow={4} onChange={onChangeDoubleInput} />
                        </Grid>
                    ))
                }
            </Grid>

            <br />
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={register}>Cadastrar</Button>
            </DialogActions>
        </>
    )
}

RegisterCetip.propTypes = {
    loading: PropTypes.func,
    afterRegister: PropTypes.func
}

export default RegisterCetip